<script >
export default {
  name: 'Course5',

  created() {
    // document.title = '宪法诞生记'
  }

}
</script>

<template>
<iframe src="https://download.zjeav.com/eav-web/yhls/index.html" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>

</template>

<style scoped lang="less">
iframe {
  width: 100%;
  height: 100%;
}
</style>